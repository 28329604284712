<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <v-card-title class="display-2">All countries of the world</v-card-title>
      <v-card-text v-if="countries">
        <p class="my-2">Click on a country to explore its tiles.</p>

        <div v-for="(value, key) in countriesByAlphabet" :key="key">
          <h2 class="subtitle pt-4">{{ key }}</h2>
          <ul>
              <li v-for="country in value" :key="country.cc"><router-link :to="{name:'map', params: {country:country.id}}">{{ country.name }}</router-link></li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>



<script>
import Vue from "vue";
import tileService from "@/services/tileService";

export default {
  name: "TileCountries",
  components: {
  },
  props: {
  },
  data() {
    return {
      countries: null,
      breadcrumbItems: [
        { text: 'Tiles', exact: true, to: {name: 'map'} },
        { text: 'Country list', disabled: true },
      ],
    };
  },
  async mounted() {
    this.countries = (await tileService.getCountries()).data.data;
  },
  methods: {
    async loadData() {
    },

  },
  computed: {
    countriesByAlphabet(){
      return this.countries.reduce((acc, item) => {
        (acc[item.name.charAt(0)] = acc[item.name.charAt(0)] || []).push(item)
        return acc
      }, {});
    },
  },
};
</script>
<style lang="scss">
</style>

